import type RemoteModuleConfig from '../../core/remote-module-config';
import type { UserState } from '../../core/store/reducers/user/types';
import type { ModuleConfig, ModuleConfigKey } from '../../modules/types';
import config from '../../config';
import { ROOT_MODULE_KEY } from '../../constants';
import MODULES, { FEATURE_FLAGGED_MODULES, MODULE_SORT_ORDER } from '../../modules';
import AuthenticationClient from '../authentication';
import LoggerClient from '../logger';
import ModuleConfigManager from './config';
import { EXTENSION_MODULE_MAP } from './constants';
import ModuleEventManager from './event';

/**
 * ModuleClient is the wrapper around the module-related ecosystem i.e. remote modules, events, configurations, etc.
 * It serves as the orchestrator for managing and interacting with all the configured remote
 * modules within Platform. It acts as a central interface for interacting with the remote modules.
 */
class ModuleClient {
  #logger = LoggerClient.createNamedLogger('ModuleClient');

  /**
   * Gets the ModuleEventManager instance.
   * @returns {ModuleEventManager} The ModuleEventManager instance.
   */
  get Events() {
    return ModuleEventManager;
  }

  /**
   * Gets the root module configuration.
   * @returns {ModuleConfig} The root module configuration.
   */
  get ROOT() {
    return MODULES[ROOT_MODULE_KEY];
  }

  /**
   * Gets the platform module configuration.
   * @returns {ModuleConfig} The platform module configuration.
   */
  get PLATFORM() {
    return MODULES.PLATFORM;
  }

  /**
   * Gets the MSSO module configuration.
   * @returns {ModuleConfig} The MSSO module configuration.
   */
  get MSSO() {
    return MODULES.MSSO;
  }

  /**
   * Gets the BRAINSTORM module configuration.
   * @returns {ModuleConfig} The BRAINSTORM module configuration.
   */
  get BRAINSTORM() {
    return MODULES.BRAINSTORM;
  }

  /**
   * Gets the WSO module configuration.
   * @returns {ModuleConfig} The WSO module configuration.
   */
  get WSO() {
    return MODULES.WSO;
  }

  /**
   * Gets the DATACENTER module configuration.
   * @returns {ModuleConfig} The DATACENTER module configuration.
   */
  get DATACENTER() {
    return MODULES.DATACENTER;
  }

  /**
   * Gets the DASHBOARD module configuration.
   * @returns {ModuleConfig} The DASHBOARD module configuration.
   */
  get DASHBOARD() {
    return MODULES.DASHBOARD;
  }

  /**
   * Gets the DISCOVER module configuration.
   * @returns {ModuleConfig} The DISCOVER module configuration.
   */
  get DISCOVER() {
    return MODULES.DISCOVER;
  }

  /**
   * Gets the LEARNING_CENTER module configuration.
   * @returns {ModuleConfig} The LEARNING_CENTER module configuration.
   */
  get LEARNING_CENTER() {
    return MODULES.LEARNING_CENTER;
  }

  /**
   * Formats the module extension based on the given extension and options.
   * @param extension - The extension to format.
   * @param options - The options to format the extension.
   * @param options.includePeriod - Whether to include the period in the extension.
   * @returns The formatted module extension.
   */
  #formatModuleExtension(extension?: string, { includePeriod = false } = {}): string | undefined {
    if (!extension) {
      return undefined;
    }
    const extensionWithPeriod = extension.startsWith('.') ? extension : `.${extension}`;
    const extensionWithoutPeriod = extension.startsWith('.') ? extension.slice(1) : extension;
    return (includePeriod ? extensionWithPeriod : extensionWithoutPeriod).toLowerCase();
  }

  /**
   * Takes an array of module configurations and sorts them based on the module sort order.
   * @param moduleConfigs - The module configurations to sort.
   * @returns The sorted module configurations.
   */
  #sortByModuleKey(moduleConfigs: ModuleConfig[]): ModuleConfig[] {
    return moduleConfigs.sort((a, b) => {
      const aKey = a.key;
      const bKey = b.key;
      if (aKey === bKey) {
        return 0;
      }
      return MODULE_SORT_ORDER.indexOf(aKey) > MODULE_SORT_ORDER.indexOf(bKey) ? 1 : -1;
    });
  }

  /**
   * Checks if the user has access to the given module.
   * @param module - The module configuration to check access for.
   * @param user - The user state to check access for.
   * @returns True if the user has access to the module, false otherwise.
   */
  hasModuleAccess(module: ModuleConfig, user: UserState): boolean {
    const features = user.claims.features;
    const subscriptions = user.subscriptions;
    const isHighSchoolUser = AuthenticationClient.isHighSchoolUser(user);

    // Filter out high school restricted modules if the user is a high school user.
    {
      if ([MODULES.DISCOVER?.key, MODULES.LEARNING_CENTER?.key].includes(module.key) && isHighSchoolUser) {
        return false;
      }
    }

    const featureId = module.featureId;
    const productId = module.productId;
    // If the module does not have a productId or featureId we assume the user has access.
    if (!productId && !featureId) {
      return true;
    }

    // If the module has a featureId, it's considered a "feature" so we'll
    // checks if it exists in the claims features array.
    // Otherwise, check if the user has a subscription to the module.
    return featureId
      ? AuthenticationClient.hasClaimsFeature(features, featureId)
      : AuthenticationClient.hasSubscription(subscriptions, productId);
  }

  /**
   * Checks if the given module is enabled based on if the module is
   * feature flagged and the feature flag is enabled.
   * @param moduleKey - The key of the module.
   * @returns True if the module is enabled, false otherwise.
   */
  isModuleEnabled(moduleKey: ModuleConfigKey): boolean {
    if (MODULES?.[moduleKey] && config.feature_flag_enable_all_modules) {
      return true;
    }

    const isModuleFeatureFlagged = moduleKey in FEATURE_FLAGGED_MODULES;
    const isModuleFeatureFlagEnabled = FEATURE_FLAGGED_MODULES[moduleKey];
    return !isModuleFeatureFlagged || (isModuleFeatureFlagged && isModuleFeatureFlagEnabled);
  }

  /**
   * Gets the module configuration available in the Platform based on feature flags.
   * @param moduleConfigs - The module configurations to filter.
   */
  getEnabledModules(user: UserState): ModuleConfig[] {
    const filteredModules = Object.values(MODULES).filter(
      module => this.isModuleEnabled(module.key) && this.hasModuleAccess(module, user),
    );
    return this.#sortByModuleKey(filteredModules);
  }

  /**
   * Gets the module configuration for the given module key.
   * @param moduleConfigKey - The key of the module configuration.
   * @returns The module configuration for the given module key.
   */
  getEnabledModule(moduleConfigKey: ModuleConfigKey, user: UserState): ModuleConfig | undefined {
    return this.getEnabledModules(user).find(module => module.key === moduleConfigKey);
  }

  /**
   * Gets the default module configuration based on the given extension.
   * @param extension - The extension to get the default module for.
   * @returns The default module configuration based on the given extension.
   */
  getDefaultModuleByExtension(extension: string, user: UserState): ModuleConfig | undefined {
    const formattedExtension = this.#formatModuleExtension(extension, { includePeriod: true });
    const defaultModule = formattedExtension ? EXTENSION_MODULE_MAP[formattedExtension]?.defaultModule : undefined;
    if (!defaultModule) {
      return undefined;
    }
    const enabledDefaultModule = this.getEnabledModule(defaultModule?.key, user);
    return enabledDefaultModule;
  }

  /**
   * Gets the related module configuration using the given extension.
   */
  getModulesByExtension(extension: string, user: UserState): ModuleConfig[] {
    if (!extension) {
      throw new Error('Extension is required to get the related modules.');
    }

    const formattedExtension = this.#formatModuleExtension(extension, { includePeriod: true });
    const modules = formattedExtension ? EXTENSION_MODULE_MAP[formattedExtension]?.modules || [] : [];
    if (!modules.length) {
      throw new Error(`No modules found to handle the extension: ${extension}`);
    }

    const enabledModules = modules.reduce((acc, module) => {
      if (!module) {
        return acc;
      }
      const enabledModule = this.getEnabledModule(module.key, user);
      if (enabledModule) {
        acc.push(enabledModule);
      }
      return acc;
    }, [] as ModuleConfig[]);
    return this.#sortByModuleKey(enabledModules);
  }

  /**
   * Gets the related module configurations using the given extension.
   */
  getDisabledModulesByExtension(extension: string, user: UserState): ModuleConfig[] {
    if (!extension) {
      throw new Error('Extension is required to get the related modules.');
    }

    const formattedExtension = this.#formatModuleExtension(extension, { includePeriod: true });
    const modules = formattedExtension ? EXTENSION_MODULE_MAP[formattedExtension]?.modules || [] : [];
    if (!modules.length) {
      throw new Error(`No modules found to handle the extension: ${extension}`);
    }

    return modules.filter(module => !this.getEnabledModule(module.key, user));
  }

  /**
   * @param moduleConfigKey
   * @returns The module configuration for the given planeId.
   */
  getRemoteModuleConfig(moduleConfigKey: ModuleConfigKey): RemoteModuleConfig | undefined {
    return ModuleConfigManager.getRemoteModuleConfig(moduleConfigKey);
  }

  /**
   * Sets the configured module configuration into the stored module configurations and flushes any
   * events that were waiting for the module to be registered.
   * @param remoteModuleConfig
   */
  registerRemoteModuleConfig(remoteModuleConfig: RemoteModuleConfig): void {
    this.#logger.log(
      `Module configuration received from ${remoteModuleConfig.key}, registering...`,
      remoteModuleConfig,
    );
    ModuleConfigManager.setRemoteModuleConfig(remoteModuleConfig);
    ModuleEventManager.flushEvents(remoteModuleConfig.key);
  }

  /**
   * Clears all the module configs from the config manager.
   */
  clearRemoteModuleConfigs(): void {
    ModuleConfigManager.clearRemoteModuleConfigs();
  }
}

const moduleClient = new ModuleClient();
export default moduleClient;
