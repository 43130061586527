import type { ProjectStoreSlice, StoreProject } from './types';
import Storage from '../storage';

const STORAGE_PROJECTS_KEY = 'mtb-cloud-storage-projects';

class ProjectStore {
  #storage = new Storage('sessionStorage');

  migrateOldStorage() {
    const projectsValue = sessionStorage.getItem('mtb-cloud-storage-projects');
    if (!projectsValue) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projects = Object.entries(JSON.parse(projectsValue) as { [key: string]: any });
    if (!projects.length) {
      return;
    }

    for (const [id, project] of projects) {
      this.setProject(id, {
        id,
        name       : project.name,
        displayName: project.displayName,
        extension  : project.extension,
        connection : {
          type          : project.type ?? 'local',
          itemId        : project.itemId,
          driveId       : project.driveId,
          autoSaveStatus: project.autoSaveStatus,
          cloudStatus   : project.cloudStatus,
        },
        operation: project.operation,
      });
    }

    sessionStorage.removeItem('mtb-cloud-storage-projects');
  }

  private getSlice(): ProjectStoreSlice {
    return this.#storage.getItem(STORAGE_PROJECTS_KEY) || {};
  }

  private setSlice(slice: ProjectStoreSlice) {
    this.#storage.setItem(STORAGE_PROJECTS_KEY, slice);
  }

  getProject(id: string): StoreProject | null {
    return this.getSlice()[id];
  }

  setProject(id: string, project: StoreProject) {
    const slice = this.getSlice();
    slice[id] = project;
    this.setSlice(slice);
  }

  removeProject(id: string) {
    const slice = this.getSlice();
    if (id in slice) {
      delete slice[id];
    }
    this.setSlice(slice);
  }

  getProjects(): Record<string, StoreProject> {
    return this.getSlice();
  }
}

const projectStore = new ProjectStore();

export default projectStore;
