import type { DataItem } from '../../../clients/data/types';
import type { ModuleConfigKey } from '../../../types';
import type { StorageProviderItem } from '@mtb/cloud-storage/types';
import { STORAGE_PROVIDER_KEYS } from '@mtb/cloud-storage';
import CloudStorageClient from '../../../clients/cloud-storage';
import DataClient from '../../../clients/data';
import { decodeDataRef } from '../../../clients/data/utils';
import DialogsClient from '../../../clients/dialogs';
import LoggerClient from '../../../clients/logger';
import ModuleClient from '../../../clients/module';
import MODULES from '../../../modules';
import platformStore from '../../store';

class DataService {
  #logger = LoggerClient.createNamedLogger('DataService');

  /**
   * Creates a data item from a string.
   * @param item - The string to create the data item from.
   * @returns - The created data item.
   */
  async #createFromString(item: string): Promise<DataItem> {
    try {
      const dataRef = decodeDataRef(item);
      if (dataRef.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
        const nameParts = CloudStorageClient.getNameParts(dataRef.name);
        const item = await this.select({
          moduleKey: MODULES.PLATFORM.key,
          filter   : [`.${nameParts.extension}`],
        });
        if (!item) {
          throw new Error('Failed to get data item from connection.');
        }
        return item;
      }

      const storageItem = await CloudStorageClient.getItemById(dataRef.connection);
      if (!storageItem) {
        throw new Error('Failed to get data item from connection.');
      }
      return DataClient.create(storageItem);
    } catch (error) {
      throw new Error('Failed to create data item from string.');
    }
  }

  /**
   * Create a data item from the given item.
   * @param options - The options to create the data item with.
   * @returns - The created data item.
   */
  async create(item: string | File | StorageProviderItem): Promise<DataItem> {
    // If we receive a string, we need to a bit of detective work to figure out what to do with it
    // before we can call the `create` method on the DataClient.
    if (typeof item === 'string') {
      return await this.#createFromString(item);
    }
    return await DataClient.create(item);
  }

  /**
   * Create a data item from the given passthrough id and name.
   * @param id - The passthrough id.
   * @param name - The passthrough name.
   * @returns - The created data item.
   */
  createFromPassthrough(id: string, name: string): DataItem {
    return DataClient.createFromPassthrough(id, name);
  }

  /**
   * Opens the Storage Explorer dialog and returns the selected item as a DataRef.
   * @param options
   * @returns The selected item as a DataRef.
   */
  async select(options: { moduleKey: ModuleConfigKey; filter?: string[] }): Promise<DataItem | undefined> {
    try {
      const user = platformStore.selectors.user(platformStore.getState());
      const module = ModuleClient.getEnabledModule(options.moduleKey, user);
      if (!module) {
        throw new Error(`Module with key ${options.moduleKey} is not enabled.`);
      }

      const defaultFilter = options.filter || module.storage?.passthroughFilter || [];
      const item = await DialogsClient.showStorageExplorerDialog({ defaultFilter });
      if (!item) {
        return;
      }

      const data = this.create(item);
      return data;
    } catch (error) {
      this.#logger.error(error);
    }
  }
}

export default DataService;
