import type { DataRef } from './types';

const dataRefVersion = '1';

/**
 * Encodes a DataRef object into a base64 string.
 * @param {DataRef} dataRef - The DataRef object to encode.
 * @returns {string} The encoded DataRef as a base64 string.
 * @throws Will throw an error if encoding fails.
 */
export function encodeDataRef(dataRef: Partial<DataRef>): string {
  try {
    return btoa(
      JSON.stringify({
        ...dataRef,
        version: dataRefVersion,
      }),
    );
  } catch (error) {
    throw Error('Failed to encode dataRef.');
  }
}

/**
 * Decodes a base64 string into a DataRef object.
 * @param {string} dataRefId - The base64 encoded DataRef string.
 * @returns {DataRef} The decoded DataRef object.
 * @throws Will throw an error if decoding fails.
 */
export function decodeDataRef(dataRefId: string): DataRef {
  try {
    return JSON.parse(atob(dataRefId));
  } catch (error) {
    throw Error('Failed to decode dataRef.');
  }
}

/**
 * Decodes the DataRef object, updates it, and then encodes it back into a base64 string.
 * @param dataRefId - The base64 encoded DataRef string.
 * @param update - The partial DataRef object to update the DataRef with.
 * @returns The updated DataRef as a base64 string.
 */
export function updateDataRef(dataRefId: string, update: Partial<DataRef> = {}): string {
  const dataRef = decodeDataRef(dataRefId);
  const updatedDataRef = {
    ...dataRef,
    ...update,
    version: dataRefVersion,
  };
  return encodeDataRef(updatedDataRef);
}

/**
 * Creates a new DataRef using the provided connection.
 * @param connection - The connection to associate with the created DataRef.
 * @returns {string} The encoded DataRef as a base64 string.
 * @throws Will throw an error if the DataRef creation fails.
 */
export function createDataRef({
  name,
  connection,
}: {
  name: string;
  connection: Partial<DataRef['connection']>;
}): string {
  return encodeDataRef({
    name,
    connection: connection as DataRef['connection'],
  });
}
