import { useCallback, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Spacer,
  Stack,
  Typography,
} from '@mtb/ui';
import dialogClient from '../../../../clients/dialog';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { getFolderName } from '../../../../utils';
import { CloudExplorerGooglePickerAlert } from '../../CloudExplorerGooglePickerAlert';
import { useCloudExplorer } from '../../hooks';

type ProviderSaveSettingsProps = { disabled?: boolean };
export const ProviderSaveSettings = ({ disabled = false }: ProviderSaveSettingsProps) => {
  const [t] = useTranslation();
  const { provider } = useCloudExplorer();
  const { defaultSaveFolder, isDefault, toggleDefault, setDefaultSaveFolder } = useProvider(provider);
  const saveFolderName = useMemo(
    () => getFolderName(provider, defaultSaveFolder),
    [defaultSaveFolder, provider],
  );

  const handleOnClick = useCallback(async () => {
    const selection = await dialogClient.selectFolder(provider);

    // clicked away / closed dialog
    if (typeof selection === 'boolean') {
      return;
    }

    // no selection return null, set root, otherwise folder
    const folder =
      selection === null
        ? null
        : {
          id      : selection.id,
          name    : selection.name,
          parentId: selection.parentId,
          driveId : selection.driveId,
          webUrl  : selection.parentFolderUrl,
        };

    setDefaultSaveFolder(folder);
  }, [provider, setDefaultSaveFolder]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
    <FormControl as="fieldset">
      <Typography
        as="legend"
        variant="title-md"
        weight="semibold">
        {t('connection.settings')}
      </Typography>
      <Stack
        alignItems="center"
        data-testid="account-info"
        direction="row"
        flex="1 0 auto"
        flexWrap="wrap"
        gap={1}
        p={2}>
        <CloudExplorerGooglePickerAlert />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <FormControlLabel
          control={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture
            <Checkbox
              checked={isDefault}
              disabled={disabled}
              onClick={toggleDefault} />
          }
          label={
            <>
              <Typography
                color="text.primary"
                variant="title-sm"
                weight="semibold">
                {t('connection.saveNewFilesTo')}
              </Typography>
              <Typography
                color="text.secondary"
                variant="title-sm"
                weight="regular">
                {saveFolderName}
              </Typography>
            </>
          }
          value="end" />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <Spacer />
        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Missing placeholder, onPointerEnterCapture, onPointerLeaveCapture */}
        <Button
          color="default"
          disabled={disabled}
          variant="contained"
          onClick={handleOnClick}>
          {t('connection.change')}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default ProviderSaveSettings;
